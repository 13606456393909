import React from "react"
import Layout from "../components/layout"

export default function Home() {
  return (
    
    <div>
      <Layout>
        <div class="mt-24 px-20 text-3xl md:text-5xl text-center font-bold">
            404...Oops! The page you are looking for is not available!
        </div>
    
      </Layout>
    </div>
  );
}
